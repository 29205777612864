import Button from 'devextreme-react/button';
import { SingleCard } from './layouts';
import { msalInstance } from './auth/authConfig.js';


const handleLogin = () => {
  const loginRequest = {
    scopes: ["api://e69fffeb-3784-44a4-bac7-27241098ece5/mywayy-cloud-access", "User.Read"]
  };

  msalInstance.loginRedirect(loginRequest)
    .then(response => {
      console.log("Successfully logged in:", response);
      // MyWayyApi.TestApi.apiTestGet({
      //   headers: {
      //     "Authorization": "Bearer " + response.accessToken
      //   }
      // }
      // ).then((response) => {
      //   console.log(response);
      // })
      //   .catch(error => { console.error("Error during login:", error); })

    })
    .catch(error => {
      console.error("Error during login:", error);
    });
};

export default function UnauthenticatedContentAad() {

  return (
    <SingleCard title="Logowanie">
      <Button text="Zaloguj przez AAD" onClick={handleLogin}></Button>
    </SingleCard>
  );
}
