export const navigation = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Projekty',
    path: '/projects',
    icon: 'activefolder'
  }
  ];
