import { PublicClientApplication } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        clientId: "e69fffeb-3784-44a4-bac7-27241098ece5",
        authority: "https://login.microsoftonline.com/8022138f-2d8e-4172-a38f-15f6a64dda9d",
        redirectUri: window.location.origin + "/"
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false
    }
};

export const scopes = ["api://e69fffeb-3784-44a4-bac7-27241098ece5/mywayy-cloud-access", "User.Read"]

export const msalInstance = new PublicClientApplication(msalConfig);