import { msalConfig, scopes } from '../auth/authConfig';
import defaultUser from '../utils/default-user';
import { MyWayyApi } from './Api';
import { IMsalContext } from "@azure/msal-react";

export async function signIn(email: string, password: string) {
  try {
    // Send request
    console.log(email, password);

    return {
      isOk: true,
      data: defaultUser
    };
  }
  catch {
    return {
      isOk: false,
      message: "Authentication failed"
    };
  }
}

export async function getUser(context: IMsalContext) {
  try {
    // Send request
    const request = {
      scopes: scopes,
      account: context.accounts[0]
    }

    const tokenResponse = await context.instance.acquireTokenSilent(request)
    console.log(tokenResponse);
    const result = await MyWayyApi.TestApi.apiTestGet();


    if (result.status === 200) {
      return {
        isOk: true,
        data: defaultUser
      };
    } else {
      return {
        isOk: false
      }
    }
  }
  catch (e) {
    console.log("Failed to get user");
    return {
      isOk: false
    };
  }
}

export async function createAccount(email: string, password: string) {
  try {
    // Send request
    console.log(email, password);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to create account"
    };
  }
}

export async function changePassword(email: string, recoveryCode?: string) {
  try {
    // Send request
    console.log(email, recoveryCode);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  }
}

export async function resetPassword(email: string) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}
