/* tslint:disable */
/* eslint-disable */
/**
 * MyWayy.Cloud
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface MyWayyContainerInfo
 */
export interface MyWayyContainerInfo {
    /**
     * 
     * @type {string}
     * @memberof MyWayyContainerInfo
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MyWayyContainerInfo
     */
    'image'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MyWayyContainerInfo
     */
    'status'?: string | null;
}

/**
 * ProjectFilesUploadApi - axios parameter creator
 * @export
 */
export const ProjectFilesUploadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [projectId] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectFilesUploadPost: async (projectId?: string, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProjectFilesUpload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectFilesUploadApi - functional programming interface
 * @export
 */
export const ProjectFilesUploadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectFilesUploadApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [projectId] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProjectFilesUploadPost(projectId?: string, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProjectFilesUploadPost(projectId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectFilesUploadApi - factory interface
 * @export
 */
export const ProjectFilesUploadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectFilesUploadApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [projectId] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectFilesUploadPost(projectId?: string, file?: File, options?: any): AxiosPromise<void> {
            return localVarFp.apiProjectFilesUploadPost(projectId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectFilesUploadApi - object-oriented interface
 * @export
 * @class ProjectFilesUploadApi
 * @extends {BaseAPI}
 */
export class ProjectFilesUploadApi extends BaseAPI {
    /**
     * 
     * @param {string} [projectId] 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectFilesUploadApi
     */
    public apiProjectFilesUploadPost(projectId?: string, file?: File, options?: AxiosRequestConfig) {
        return ProjectFilesUploadApiFp(this.configuration).apiProjectFilesUploadPost(projectId, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectsApi - axios parameter creator
 * @export
 */
export const ProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [myWayyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectsPost: async (myWayyId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (myWayyId !== undefined) {
                localVarQueryParameter['myWayyId'] = myWayyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [myWayyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectsStartPut: async (myWayyId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Projects/start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (myWayyId !== undefined) {
                localVarQueryParameter['myWayyId'] = myWayyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [myWayyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectsStopPut: async (myWayyId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Projects/stop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (myWayyId !== undefined) {
                localVarQueryParameter['myWayyId'] = myWayyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectsApi - functional programming interface
 * @export
 */
export const ProjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProjectsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MyWayyContainerInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProjectsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [myWayyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProjectsPost(myWayyId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProjectsPost(myWayyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [myWayyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProjectsStartPut(myWayyId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProjectsStartPut(myWayyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [myWayyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProjectsStopPut(myWayyId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProjectsStopPut(myWayyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectsApi - factory interface
 * @export
 */
export const ProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectsGet(options?: any): AxiosPromise<Array<MyWayyContainerInfo>> {
            return localVarFp.apiProjectsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [myWayyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectsPost(myWayyId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiProjectsPost(myWayyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [myWayyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectsStartPut(myWayyId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiProjectsStartPut(myWayyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [myWayyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectsStopPut(myWayyId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiProjectsStopPut(myWayyId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectsApi - object-oriented interface
 * @export
 * @class ProjectsApi
 * @extends {BaseAPI}
 */
export class ProjectsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public apiProjectsGet(options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).apiProjectsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [myWayyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public apiProjectsPost(myWayyId?: string, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).apiProjectsPost(myWayyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [myWayyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public apiProjectsStartPut(myWayyId?: string, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).apiProjectsStartPut(myWayyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [myWayyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public apiProjectsStopPut(myWayyId?: string, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).apiProjectsStopPut(myWayyId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TestApi - axios parameter creator
 * @export
 */
export const TestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTestGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestApi - functional programming interface
 * @export
 */
export const TestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTestGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTestGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TestApi - factory interface
 * @export
 */
export const TestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTestGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiTestGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestApi - object-oriented interface
 * @export
 * @class TestApi
 * @extends {BaseAPI}
 */
export class TestApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiTestGet(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiTestGet(options).then((request) => request(this.axios, this.basePath));
    }
}


