import React, { useEffect, useState, useCallback, useRef } from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Selection
} from 'devextreme-react/data-grid';
import { MyWayyApi } from '../../api/Api'
import { MyWayyContainerInfo } from '../../api/openapi';
import GenericToolbar from '../../components/GenericToolbar'
import notify from 'devextreme/ui/notify';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import FileUploader from 'devextreme-react/file-uploader';
import axios from 'axios';
import { LoadIndicator } from 'devextreme-react/load-indicator';

const Projects = () => {
  const [data, setData] = useState<MyWayyContainerInfo[]>()
  const [selectedItem, setSelectedItem] = useState<any>(null)
  const [popupVisible, setPopupVisible] = useState(false)
  const [file, setFile] = useState<any>(null)
  const [uploading, setUploading] = useState(false)
  const popupRef = useRef<FileUploader>()

  const refresh = useCallback(() => {
    MyWayyApi.ProjectsApi.apiProjectsGet()
      .then(x => {
        setData(x.data)
      })
  }, [])

  const startServer = useCallback((item: any) => {
    const id = item.id
    MyWayyApi.ProjectsApi.apiProjectsStartPut(id)
      .then(x => {
        notify("Ok", "success")
        refresh()
      }).catch(x => {
        notify("Błąd", "error")
      })
  }, [])

  const stopServer = useCallback((item: any) => {
    const id = item.id
    MyWayyApi.ProjectsApi.apiProjectsStopPut(id)
      .then(x => {
        notify("Ok", "success")
        refresh()
      }).catch(x => {
        notify("Błąd", "error")
      })
  }, [])

  const buttons = [
    {
      text: "Odśwież", onClick: () => {
        refresh()
      }
    },
    { text: "Start", onClick: () => startServer(selectedItem), disabled: selectedItem === null },
    { text: "Stop", onClick: () => stopServer(selectedItem), disabled: selectedItem === null },
    { text: "Prześlij pliki", onClick: () => { setPopupVisible(true) }, disabled: selectedItem === null },
  ]

  useEffect(() => {
    refresh()
  }, [])

  const closePopup = () => {
    setPopupVisible(false)
    setFile(null)
  }

  const emailButtonOptions = {
    icon: 'email',
    text: 'Wyślij',
    onClick: async () => {
      setUploading(true)
      var fileData = new FormData();
      fileData.append("file", file, file.name);

      await axios.post(
        MyWayyApi.BASE_URL + '/api/ProjectFilesUpload',
        //'http://localhost:5275/api/ProjectFilesUpload',
        fileData,
        {
          params: {
            'projectId': selectedItem.id
          },
          headers: {
            'Content-Type': `multipart/form-data`
          }
        }
      )
        .then(s => {
          if(s.status === 204){
            notify("Zawartość pliku zip nie pasuje do projektu", "error")
          }else{
            notify("Ok", "success")
          }
          
          closePopup()
        })
        .catch(err => {
          notify("Błąd", "error")
        })
        .finally(() => {
          setUploading(false)
        });

      // axios.post(`api/ProjectFilesUpload`, fileData, {
      //   headers: {
      //     'Content-Type': `multipart/form-data`
      //   }
      // }).catch(err => {
      //   console.log(err)
      // })
    },
  };
  const closeButtonOptions = {
    icon: 'close',
    text: 'Zamknij',
    onClick: () => closePopup(),
  };

  return (
    <React.Fragment>



      <Popup
        visible={popupVisible}
        onHiding={() => closePopup()}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={false}
        showTitle={true}
        title="Wybierz plik z projektem"
        container=".dx-viewport"
        width={500}
        height={380}
        disabled={uploading}
      >

        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="before"
          options={emailButtonOptions}
          disabled={file === null}
        />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={closeButtonOptions}
        />
        {uploading && <LoadIndicator id="large-indicator" height={60} width={60} />}
        <form id="form"
          // ref={this.formElement} 
          method="post" action="" encType="multipart/form-data">

          <div className="fileuploader-container">
            <FileUploader selectButtonText="wybierz ZIP" labelText="" accept=".zip*"
              // uploadMode="useForm"
              uploadFile={file => setFile(file)} />
          </div>

        </form>
      </Popup>

      <h2 className={'content-block'}>Projekty</h2>

      <GenericToolbar header="Odśwież" buttons={buttons} customHeaderRenderer={undefined} toolbarId={undefined} className={undefined}></GenericToolbar>
      <DataGrid
        className={'dx-card wide-card'}
        dataSource={data}
        showBorders={false}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        keyExpr={"id"}
        onSelectionChanged={x => {
          if (x.selectedRowsData.length === 1) {
            setSelectedItem(x.selectedRowsData[0])
          } else {
            setSelectedItem(null)
          }
        }}
      >
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={false} />
        <FilterRow visible={true} />
        <Selection mode="single"
          showCheckBoxesMode='none'
        />

        <Column dataField={'id'} />
        <Column dataField={'image'} />
        <Column dataField={'status'} />

      </DataGrid>
    </React.Fragment>
  )
}

export default Projects