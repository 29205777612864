import { scopes } from "../auth/authConfig";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

export const AadGetTokenData = (instance, account) => {
    let getTokenData = null

    getTokenData = async () => {
        let tokenData = ''
        const request = {
            scopes,
            account: account
        };
        await instance.acquireTokenSilent(request)
            .then(x => {
                tokenData = x
            })
            .catch(x => {
                if (x instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(request);
                }
            })

        return tokenData
    }

    return getTokenData;
};



export const AadLogout = async (instance, accounts) => {
    await instance.logoutRedirect({
        account: accounts[0]
    });
}