import axios from "axios"
import { ProjectsApi, TestApi } from "./openapi"

export const BASE_URL = "."

export const MyWayyApi = {
    BASE_URL: BASE_URL,
    ProjectsApi: new ProjectsApi(null, BASE_URL),
    TestApi: new TestApi(null, BASE_URL),
}

let GetToken = () => ""

export const SetGetToken = (getToken) => { GetToken = getToken }

axios.interceptors.request.use(async (config) => {
    const token = await GetToken()
    config.headers["Authorization"] = `Bearer ${token.accessToken}`
    return config;
});