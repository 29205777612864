import React from 'react';
import './home.scss';
import { msalInstance } from '../../auth/authConfig.js';
import { MyWayyApi } from '../../api/Api';
import { scopes } from '../../auth/authConfig';


const handleLogin = () => {
  const loginRequest = {
    scopes: scopes
  };

  msalInstance.loginPopup(loginRequest)
    .then(response => {
      console.log("Successfully logged in:", response);
      MyWayyApi.TestApi.apiTestGet({
        headers: {
          "Authorization": "Bearer " + response.accessToken
        }
      }
      ).then((response) => {
        console.log(response);
      })
        .catch(error => { console.error("Error during login:", error); })

    })
    .catch(error => {
      console.error("Error during login:", error);
    });
};

const handleLogout = () => {
  msalInstance.logout();
}

export default function Home() {
  return (
    <React.Fragment>
      <h2 onClick={() => { handleLogout() }}>Wyloguj</h2>
      <h2 className={'content-block'}>Home</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <h2>Oprogramowanie MyWayy</h2>
          <p>MyWayy to oprogramowanie BMS, które zapewnia monitoring oraz zarządzanie wszystkimi elementami budynku. Szybki dostęp do wszystkich parametrów oraz możliwość tworzenia harmonogramów pracy pozwalają na efektywne wykorzystanie zasobów budynku. </p>
        </div>
      </div>
    </React.Fragment>
  )
}
