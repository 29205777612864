import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { getUser, signIn as sendSignInRequest } from '../api/auth';
import type { User, AuthContextType } from '../types';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { AadGetTokenData } from '../auth/aad';
import { SetGetToken } from '../api/Api';

function AuthProvider(props: React.PropsWithChildren<unknown>) {
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState(true);
  const isAuthenticated = useIsAuthenticated();
  const msalContext = useMsal();

  useEffect(() => {
    (async function () {
      console.log("AuthProvider: useEffect");
      if (!isAuthenticated || msalContext.accounts.length === 0) {
        console.warn("No accounts detected");
        setLoading(false);
        return;
      } else {
        const getTokenData = AadGetTokenData(msalContext.instance, msalContext.accounts[0]);
        SetGetToken(getTokenData)
        const result = await getUser(msalContext);
        if (result.isOk) {
    

          setUser(result.data);
        }
      }


      setLoading(false);
    })();
  }, [isAuthenticated]);

  const signIn = useCallback(async (email: string, password: string) => {
    const result = await sendSignInRequest(email, password);
    if (result.isOk) {
      setUser(result.data);
    }

    return result;
  }, []);

  const signOut = useCallback(() => {
    setUser(undefined);
  }, []);


  return (
    <AuthContext.Provider value={{ user, signIn, signOut, loading }} {...props} />
  );
}

const AuthContext = createContext<AuthContextType>({ loading: false } as AuthContextType);
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
